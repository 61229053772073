<template>
  <div>
    <ZgAlert v-if="!valid_domain_name" :alert="invalidDomainNameAlertMessage"/>
    <h1 class="heading">Basic Email</h1>
    <h2 class="header">Details</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>User Chose to Skip Email</dt>
          <dd v-if="!editing">{{ basicEmail.skip | yesno }}</dd>
          <div v-else class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="skipEmail" v-model="basicEmail.skip" />
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <dt>
            Email Address
            <i class="fa fa-info-circle" :content="'This field only requires username'" v-tippy />
          </dt>
          <dd v-if="!editing">{{basicEmail.email_address ? basicEmail.email_address : "No address given"}}</dd>
          <input type="email" v-else v-model="basicEmail.email_address" class="form-control" />
        </div>
        <div class="col">
          <dt>Domain Name</dt>
          <small><b>*do not include www. prefix</b></small><br/>
          <small><b>*must include TLD suffix .com, .org, .biz, .net or .us</b></small>
          <dd>www.{{ emailDomainName }}</dd>
          <input v-if="editing" v-model="domainName" class="form-control"><br/>
        </div>
      </div>
      <br />
      <div>
        <button class="btn btn-info btn-sm" v-if="!editing" @click="handleEditClick" >Edit</button>
        <button class="btn btn-success btn-sm" v-if="editing" @click="handleSaveClick">Save</button>
        <button class="btn btn-danger btn-sm" v-if="editing" @click="handleCancelEditClick">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { yesNoMixin } from '@/components/mixins';
import ZgAlert from '@/components/utils/ZgAlert.vue';

const allowedTLDs = '.com|.net|.org|.biz|.us';
const domainNameValidationRegex = new RegExp(`^(www.)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(${allowedTLDs})$`);

export default {
  name: 'BasicEmailView',
  props: ['orderTask', 'edit', 'pr_uuid'],
  components: { ZgAlert },
  data() {
    return {
      editing: this.edit,
      domainName: 'example.com',
      valid_domain_name: true,
      invalidDomainNameAlertMessage: {
        primary: 'This domain name is missing a TLD suffix',
        secondary: 'Include one of [.com, .org, .biz, .net or .us] allowed TLDs. Refresh the page and try again',
        type: 'alert alert-danger'
      }
    };
  },
  mixins: [yesNoMixin],
  computed: {
    ...mapState('orders', ['order']),
    basicEmail() {
      return this.orderTask.order_task_data;
    },
    emailDomainName() {
      const basicEmail = this.orderTask.order_task_data;
      if (basicEmail.full_email_address) {
        return basicEmail.full_email_address.substring(basicEmail.full_email_address.lastIndexOf('@') + 1);
      }
      var valid_domain_name_only = false;
      if (basicEmail.domain_name) {
        valid_domain_name_only = domainNameValidationRegex.test(basicEmail.domain_name);
      }
      return valid_domain_name_only ? basicEmail.domain_name : this.domainName;
    },
  },
  methods: {
    handleSaveClick() {
      this.editing = false;
      this.valid_domain_name = domainNameValidationRegex.test(this.emailDomainName);
      if (this.valid_domain_name && this.basicEmail.email_address) {
        this.$store.dispatch('partnerRequest/repairEmail', { pr_uuid: this.pr_uuid, domainName: this.domainName, email_address: this.basicEmail.email_address })
          .then(() => {
            this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.pr_uuid })
              .then(() => {
                this.$router.push({
                  name: 'OrderTasksView',
                  params: { uuid: this.order_uuid }
                });
              });
          });
      } else {
        throw new Error('Not valid domain name or email address')
      }
    },
    handleCancelEditClick() {
      this.editing = false;
    },
    handleEditClick() {
      this.editing = true;
    }
  }
};
</script>
<style lang="scss" scoped>
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
</style>
