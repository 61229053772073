<template>
  <tr v-if="isExternalPartner">
    <td>{{ partnerRequest.partner_type }}</td>
    <td>{{ partnerRequest.jurisdiction }}</td>
    <td>{{ partnerRequest.status }} <span v-if="partnerRequest.status_detail !== null">({{ partnerRequest.status_detail }})</span></td>
    <td>{{ sentDatetime }}</td>
    <td>{{ partnerRequest.partner_request_external_id}}</td>
    <td>{{ partnerRequest.external_status}}</td>
    <td v-if="showReset">
      <span class="btn btn-warning btn-sm" @click="resetPartnerRequestClicked = true">Reset</span>
      <div v-if="hasMatchingBLR" class="mt-sm-2">
        <span class="btn btn-warning btn-sm" @click="handleCompleteBLRClick">Confirm</span>
      </div>
    </td>
    <td v-if="showPrivacyRepair"><span v-if="showAction" class="btn btn-success btn-sm" @click.once="handleRepairDomainClick">Repair</span></td>
    <td v-else-if="showDomainRepair"><span v-if="showAction" class="btn btn-success btn-sm" @click.once="handleRepairDomainClick">Repair</span></td>
    <td v-else-if="showRepairRefresh"><span v-if="showAction" class="btn btn-success btn-sm" @click="handleRepairRefreshClick">Refresh</span></td>
    <td v-else-if="showRepair"><span v-if="showAction" class="btn btn-success btn-sm" @click="handleRepairTaskClick">Repair</span></td>
    <td v-else><span v-if="showAction" class="btn btn-success btn-sm" @click.once="handleSendClick">Send</span></td>
    <td v-if="showExchange"><button class="btn btn-warning btn-sm" @click="exchangePartnerRequestClicked = true"
      data-toggle="tooltip" data-placement="top" title="Swap this partner request with one from another order">Exchange</button>
    </td>

    <ResetPartnerRequestModal v-if="resetPartnerRequestClicked"  @close="resetPartnerRequestClicked = false" @canceled="refresh" v-bind:partnerRequest="partnerRequest"></ResetPartnerRequestModal>
    <ExchangePartnerRequestModal
      v-if="exchangePartnerRequestClicked"
      @close="exchangePartnerRequestClicked = false"
      @canceled="refresh"
      v-bind:partnerRequest="partnerRequest"
      v-bind:businessEntity="businessEntity"
      v-bind:uuid="uuid"
      v-bind:partnerRequestDomains="partnerRequestDomains">
    </ExchangePartnerRequestModal>
  </tr>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { hasPermissionMixin, manifestMixin } from '../mixins';
import ResetPartnerRequestModal from './reset-partner-request-modal';
import ExchangePartnerRequestModal from './exchange-partner-request-modal';

export default {
  name: 'partner-request-list-item',

  components: {
    ResetPartnerRequestModal,
    ExchangePartnerRequestModal
  },

  props: ['partnerRequest', 'uuid', 'businessEntity', 'partnerRequestDomains', 'bundledPrivacy'],

  mixins: [hasPermissionMixin, manifestMixin],

  data() {
    return {
      resetPartnerRequestClicked: false,
      exchangePartnerRequestClicked: false
    };
  },

  computed: {
    ...mapState('orders', ['businessLicenseReport']),
    showAction() {
      return (
        // don't show for non-sendable statuses:
        this.partnerRequest.status !== 'sent' && this.partnerRequest.status !== 'canceled' && this.partnerRequest.status !== 'requires manual cancel');
    },
    showReset() {
      return (
        // only show for "reset"-able statuses:
        (this.partnerRequest.status === 'sent' || this.partnerRequest.status === 'declined' || this.partnerRequest.status === 'canceled')
        // only show if either non-webservices request or we have webservices:edit scoped permissions:
        && (this.hasPermission('edit:webservices') || this.hasPermission('access:developers') || !['domain_name_reg', 'basic_email', 'domain_name_privacy'].includes(this.partnerRequest.partner_type)));
    },
    showDomainRepair() {
      return this.partnerRequest.partner_type === 'domain_name_reg'
      && (this.partnerRequest.status === 'declined' && this.partnerRequest.status_detail === 'skipped domain');
    },
    showPrivacyRepair() {
      return this.partnerRequest.partner_type === 'domain_name_privacy'
      && ((this.partnerRequest.status === 'not_sent'
      && this.partnerRequest.status_detail == 'StandAlone Privacy purchase with no targeted domain')
      || (this.partnerRequest.status === 'declined'
      && this.partnerRequest.status_detail == 'skipped domain'));
    },
    showRepairRefresh() {
      return this.partnerRequest.partner_type === 'domain_name_reg'
      && (this.partnerRequest.status === 'not_sent' && (this.partnerRequest.status_detail &&
      (this.partnerRequest.status_detail.includes('415: Domain Credentials Authentication Error')
      || this.partnerRequest.status_detail.includes("Domain doesn't exist")
      || this.partnerRequest.status_detail.includes('domain object missing')
      || this.partnerRequest.status_detail.includes('primary missing or empty in order task')
      || this.partnerRequest.status_detail.includes('primary missing or empty in partner request'))));
    },
    showRepair() {
      return this.partnerRequest.status === 'declined' || this.partnerRequest.status_detail === "email record must have domain selected";
    },
    sentDatetime() {
      return this.partnerRequest.status_updated ? (moment(this.partnerRequest.status_updated).format('MMMM DD YYYY, h:mm:ss a')) : '';
    },
    showExchange() {
      return this.partnerRequest.partner_type === 'domain_name_reg';
    },
    hasMatchingBLR() {
      return this.partnerRequest.partner_type === 'business_license_report'
        && this.businessLicenseReport
        && this.businessLicenseReport.order_url
        && (this.businessEntity.business_entity_uuid === this.businessLicenseReport.business_entity_uuid);
    },
    isExternalPartner() {
      /* Determine whether this is an external partner request (used to determine if we should display "send"/"reset"
        buttons.  Currently the only "non-external" partner request is a registered agent request with ZB partner */
      return !(this.partnerRequest.partner_type === 'registered_agent_service'
               && this.partnerRequest.partner_display_name === 'ZenBusiness');
    }
  },

  methods: {
    handleSendClick() {
      this.$store.dispatch('partnerRequest/sendPartnerRequest', { partnerRequestUuid: this.partnerRequest.partner_request_uuid })
        .then(() => {
          this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.uuid });
        });
    },
    handleCompleteSendClick() {
      this.$store.dispatch('partnerRequest/completeSendPartnerRequest', { partnerRequestUuid: this.partnerRequest.partner_request_uuid })
        .then(() => {
          this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.uuid });
        });
    },
    handleCompleteBLRClick() {
      window.open(this.businessLicenseReport.order_url, '_blank');
      this.handleCompleteSendClick();
    },
    handleRepairDomainClick() {  // This repair both domain reg and domain privacy
      this.$router.push({
        name: 'RepairView',
        params: { partnerRequest: this.partnerRequest, order_uuid: this.uuid }
      });
    },
    handleRepairRefreshClick() {
      this.$store.dispatch('partnerRequest/repairDomainRefresh', { pr_uuid: this.partnerRequest.partner_request_uuid, order_uuid: this.uuid })
          .then(() => {
            this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.uuid })
              .then(() => {
                this.$router.push({
                  name: 'OrderTasksView',
                  params: { uuid: this.order_uuid }
                });
              });
          });
    },
    handleRepairTaskClick() {
      var data = { taskUuid: this.partnerRequest.order_task_uuid, edit: true};
      if (this.partnerRequest.partner_type === 'basic_email') {
        data['pr_uuid'] = this.partnerRequest.partner_request_uuid
      }
      this.$router.push({ name: 'OrderServiceDataView', params: data });
    },
    refresh() {
    }
  },
  mounted () {
    if (this.partnerRequest.partner_type === 'banking' && this.partnerRequest.status !== 'sent') {
      this.$store.dispatch('partnerRequest/validateBankingOrder', { partnerRequestUuid: this.partnerRequest.partner_request_uuid })
        .then(() => {
          this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.uuid })
        })
    }
  }
};
</script>

<style lang="scss">

</style>
