<template>
  <tr>
    <td v-if="discountedPlans.includes(orderBillingItem.service)">{{ orderBillingItem.service }} <span class="badge bg-primary">$50 off first year</span></td>
    <td v-else>{{ orderBillingItem.service }}</td>
    <td v-if="showUuids">{{ orderBillingItem.uuid }}</td>
    <td>{{ orderBillingItem.jurisdiction }}</td>
    <td>{{ orderBillingItem.status }}</td>
    <td>{{ orderBillingItem.price }}</td>
    <td>{{ couponAmount }}</td>
    <td>{{ totalPaid }}</Td>
    <td>{{ orderBillingItem.period | readableRecurringPeriod }}</td>
    <td v-if="orderBillingItem.status === 'cancel_renewal'">
      <div class="btn btn-sm btn-info" @click="undoCancellationClick = true"
      data-toggle="tooltip" data-placement="top" title="Undo Cancellation">Undo Cancellation</div>
    </td>
    <ConfirmUndoCancellationModal v-if="undoCancellationClick"
    @close="undoCancellationClick = false"
    @refresh="refresh"
    v-bind:uuid="orderBillingItem.uuid"
    :service="orderBillingItem.service"
    :price="orderBillingItem.price"
    :period="orderBillingItem.period"
    />
  </tr>
</template>

<script>
import { mapState } from 'vuex';
import { refundsMixin, readableRecurringPeriodMixin } from '../../mixins';
import ConfirmUndoCancellationModal from './confirm-undo-cancel-modal';


export default {
  name: 'OrderBillingListSubItem',
  components: { ConfirmUndoCancellationModal },
  mixins: [refundsMixin, readableRecurringPeriodMixin],
  props: ['orderBillingItem', 'showUuids'],

  data() {
    return {
      discountedPlans: ['truic_starter_plan_v2', 'starter_plan_v12'],
      undoCancellationClick: false
    };
  },

  computed: {
    ...mapState('orders', ['order', 'subscriptionBillingItems']),
    couponAmount() {
      return this.orderBillingItem.coupon ? this.orderBillingItem.coupon.amount : 0
    },
    totalPaid() {
      return this.orderBillingItem.price - this.couponAmount
    }
  },

  methods: {
    refresh(){
      this.$store.dispatch('orders/fetchSubscriptionBillingItems', { uuid: this.order.order_uuid });
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
