<template>
  <tr>
    <td>{{ orderBillingItem.service }}</td>
    <td v-if="showUuids">{{ orderBillingItem.uuid }}</td>
    <td>{{ orderBillingItem.jurisdiction }}</td>
    <td>{{ orderBillingItem.status }}</td>
    <td v-if="!price">{{ orderBillingItem.price }}</td>
    <td v-if="price">{{ price }}</td>
    <td>{{ couponAmount }}</td>
    <td>{{ orderBillingItem.fees }}</td>
    <td v-if="this.showRefunds">{{ orderBillingItem.amount_refunded }}</td>
    <td v-if="this.showRefunds"><input v-if="isRefundable" class="amount form-control" v-model="refund.amount" /></td>
    <td v-if="this.showRefunds"><div v-if="isRefundable" class="btn btn-sm btn-danger" @click.once="handleRefundClick">Refund</div></td>
  </tr>
</template>

<script>
import { refundsMixin } from '../../mixins';

export default {
  name: 'OrderBillingListTransItem',
  mixins: [refundsMixin],
  props: ['orderBillingItem', 'uuid', 'showRefunds', 'price', 'showUuids'],

  data() {
    return {
      refund: {
        item_uuid: this.orderBillingItem.uuid,
        amount: this.orderBillingItem.price + this.orderBillingItem.fees - this.orderBillingItem.amount_refunded
      }
    };
  },

  computed: {
    isRefundable() {
      return this.orderBillingItem.price + this.orderBillingItem.fees - this.orderBillingItem.amount_refunded > 0;
    },
    couponAmount() {
      return this.orderBillingItem.coupon ? this.orderBillingItem.coupon.amount : 0
    },
  },

  methods: {
    handleRefundClick() {
      this.$store.dispatch('orders/refundOrderBillingItem', { uuid: this.uuid, data: this.refund })
        .then(() => {
          this.$store.dispatch('orders/fetchTransactionalBillingItems', { uuid: this.uuid });
        });
    }
  }

};
</script>

<style lang="scss" scoped>
  .amount {
    width: 65px;
  }
</style>
