<template>
  <div>
    <div v-if="order.api_version == 2">
      <h2 class="header">!!! Orders V2 !!!</h2>
      <div class="box">
        <b>NOTE: Created with Orders V2.</b> Transactional items are no longer charged separately
        and instead are included and paid on the First Invoice listed.  If you need to give a refund on a transactional
        item, do so by refunding the amount from the first invoice.
      </div>
    </div>
    <h2 class="header">Transactional Billing Items (<span style="text-decoration: underline" @click="showUuids = !showUuids">Show UUIDs</span>)</h2>
    <div class="box">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th>Service</th>
                <th v-if="showUuids">UUID</th>
                <th>Jurisdiction</th>
                <th>Status</th>
                <th>Price</th>
                <th>Coupon</th>
                <th>Fees</th>
                <th v-if="order.api_version == 1">Refunded</th>
                <th v-if="order.api_version == 1">Amount to Refund</th>
              </tr>
            </thead>
            <tbody>
              <OrderBillingListTransItem v-for="transactionalBillingItem in transactionalBillingItems" v-bind:key="transactionalBillingItem.uuid" :orderBillingItem="transactionalBillingItem" :uuid="uuid" :showRefunds="order.api_version == 1" :price="transactionalBillingItem.price" :showUuids="showUuids"></OrderBillingListTransItem>
            </tbody>
            <tr>
              <th colspan="3"></th>
              <th colspan="2">Subtotal</th>
              <th>{{ sumTransactionalPrices.toFixed(2) }}</th>
            </tr>
            <tr>
              <th colspan="3"></th>
              <th colspan="2">Coupons</th>
              <th>{{ sumTransactionalCoupons.toFixed(2) }}</th>
            </tr>
            <tr>
              <th colspan="3"></th>
              <th colspan="2">Fees</th>
              <th>{{ sumTransactionalFees.toFixed(2) }}</th>
            </tr>
            <tfoot>
              <th colspan="3"></th>
              <th colspan="2">Total</th>
              <th>{{ ((sumTransactionalPrices + sumTransactionalFees)-sumTransactionalCoupons).toFixed(2) }}</th>
            </tfoot>

          </table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-if="order.api_version == 1 && isRefundable" class="btn btn-danger" @click.once="handleFullRefund">Full Refund</div>
        </div>
      </div>
    </div>
    <h2 class="header">Subscription Billing Items (<span style="text-decoration: underline" @click="showUuids = !showUuids">Show UUIDs</span>)</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th>Service</th>
                <th v-if="showUuids">UUID</th>
                <th>Jurisdiction</th>
                <th>Status</th>
                <th>Recurring Price</th>
                <th>Coupon</th>
                <th>First Period Price</th>
                <th>Period</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <OrderBillingListSubItem v-for="subscriptionBillingItem in subscriptionBillingItems" v-bind:key="subscriptionBillingItem.uuid" :orderBillingItem="subscriptionBillingItem" :showUuids="showUuids"></OrderBillingListSubItem>
            </tbody>
            <tr>
              <th colspan="4"></th>
              <th colspan="2">Subtotal</th>
              <th>{{ sumSubscriptionPrices.toFixed(2) }}</th>
            </tr>
            <tr>
              <th colspan="4"></th>
              <th colspan="2">Discounts</th>
              <th>{{ sumSubscriptionCoupons.toFixed(2) }}</th>
            </tr>
            <tfoot>
              <th colspan="4"></th>
              <th colspan="2">
                Total First Period
                <i class="fa fa-info-circle" :content="'Price includes deferred products. Deferred invoices will not be created until formation is completed or product is manually charged'" v-tippy></i>
              </th>
              <th>{{ (sumSubscriptionPrices-sumSubscriptionCoupons).toFixed(2)}}</th>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <h2 class="header">Invoices</h2>
    <div class="box">
      <small><b>* The amounts here ARE reflective of coupons or first year discounts.</b></small>
      <div class="row">
        <div class="col">
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Paid</th>
                <th>Refunded</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <OrderBillingListInvoiceItem
                v-for="billingInvoice in billingInvoices"
                v-bind:key="billingInvoice.uuid"
                :billingInvoice="billingInvoice"
                :uuid="uuid"
                :subscriptionBillingItems="subscriptionBillingItems"
                :transactionalBillingItems="transactionalBillingItems"
                :refundReasons="getRefundReasons">
              </OrderBillingListInvoiceItem>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <h2 class="header">Itemized Refunds</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th>Created By</th>
                <th>Amount</th>
                <th>Reason</th>
                <th>Reason Other</th>
                <th>Tracking</th>
              </tr>
            </thead>
            <tbody>
              <RefundListInvoiceReasonItem
                v-for="refund in refunds"
                v-bind:key="refund.uuid"
                :refund="refund"
                :uuid="uuid"
                :refund_reasons="getRefundReasons"
                :transactionalBillingItems="transactionalBillingItems">
              </RefundListInvoiceReasonItem>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import OrderBillingListTransItem from './order-billing-list-trans-item';
import OrderBillingListSubItem from './order-billing-list-sub-item';
import OrderBillingListInvoiceItem from './order-billing-list-invoice-item';
import RefundListInvoiceReasonItem from './refund-list-invoice-reason-item';
import { refundsMixin } from '../../mixins';

export default {
  name: 'OrderBillingView',

  components: {
    OrderBillingListTransItem,
    OrderBillingListSubItem,
    OrderBillingListInvoiceItem,
    RefundListInvoiceReasonItem
  },

  props: ['uuid'],

  mixins: [refundsMixin],

  data() {
    return {
      editRefundReasonClick: false,
      showUuids: false
    };
  },

  computed: {
    ...mapState('orders', ['order', 'transactionalBillingItems', 'subscriptionBillingItems', 'billingInvoices','refunds']),
    ...mapGetters('orders', ['getRefundReasons']),
    isRefundable() {
      let total = 0;
      for (let i = 0; i < this.transactionalBillingItems.length; i++) {
        total += (this.transactionalBillingItems[i].price + this.transactionalBillingItems[i].fees - this.transactionalBillingItems[i].amount_refunded);
      }
      return this.order.order_status !== 'complete' && total > 0;
    },
    sumTransactionalFees() {
      return this.transactionalBillingItems.reduce((partialSum, item) => partialSum + item.fees, 0);
    },
    sumTransactionalPrices() {
      return this.transactionalBillingItems.reduce((partialSum, item) => partialSum + item.price, 0);
    },
    sumTransactionalCoupons(){
      return this.transactionalBillingItems.reduce((partialSum, item) => partialSum + (item.coupon ? item.coupon.amount : 0), 0);
    },
    sumSubscriptionPrices() {
      return this.subscriptionBillingItems.reduce((partialSum, item) => partialSum + item.price, 0);
    },
    sumSubscriptionCoupons(){
      return this.subscriptionBillingItems.reduce((partialSum, item) => partialSum + (item.coupon ? item.coupon.amount : 0), 0);
    },
  },

  methods: {
    ...mapActions('orders', ['fetchCancelAndRefundReasons']),
    handleFullRefund() {
      this.$store.dispatch('orders/refundAllTransactional', { uuid: this.uuid })
        .then(() => {
          this.$store.dispatch('orders/fetchTransactionalBillingItems', { uuid: this.uuid });
        });
    },
    refresh() {
      this.editRefundReasonClick = false;
    },
  },

  created() {
    this.$store.dispatch('orders/fetchTransactionalBillingItems', { uuid: this.uuid });
    this.$store.dispatch('orders/fetchSubscriptionBillingItems', { uuid: this.uuid });
    this.$store.dispatch('orders/fetchBillingInvoices', { uuid: this.uuid });
    this.fetchCancelAndRefundReasons({ typeReason: 'refund' });
    this.$store.dispatch('orders/fetchRefunds', { uuid: this.uuid });
  }

};
</script>

<style lang="scss" scoped>
</style>
