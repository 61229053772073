<template>
  <div>
    <ZgAlert v-if="!valid_domain_name" :alert="invalidDomainNameAlertMessage"/>
    <h2 class="header">Repair</h2>
    <div class="box">
      <div class="row">
        <div class="col-6">
          <dd>
            <label class="form-check-label">
              Domain Name<br/>
              <small><b>*do not include www. prefix</b></small><br/>
              <small><b>*must include TLD suffix .com, .org, .biz, .net or .us</b></small>
            </label>
            <input v-model="domainName" class="form-control">www.{{ domainName }}<br/>
            <span class="btn btn-success btn-sm mt-2" @click.once="handleRepairDomain">Repair</span>
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ZgAlert from '../../utils/ZgAlert.vue';

const allowedTLDs = '.com|.net|.org|.biz|.us';
const domainNameValidationRegex = new RegExp(`^(www.)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(${allowedTLDs})$`);

export default {
  name: 'RepairView',

  props: ['partnerRequest', 'order_uuid', 'orderTask'],

  components: { ZgAlert },

  computed: {
    ...mapState('orders', ['order']),
    ny_publication() {
      return this.orderTask.order_task_data;
    }
  },

  methods: {
    handleRepairDomain() {
      this.valid_domain_name = domainNameValidationRegex.test(this.domainName);
      if (this.valid_domain_name) {
        if (this.partnerRequest.partner_type == 'domain_name_reg') {
          this.$store.dispatch('partnerRequest/repairDomain', { pr_uuid: this.partnerRequest.partner_request_uuid, domainName: this.domainName })
            .then(() => {
              this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.partnerRequest.partner_request_uuid })
                .then(() => {
                  this.$router.push({
                    name: 'OrderTasksView',
                    params: { uuid: this.order_uuid }
                  });
                });
            });
        } else if (this.partnerRequest.partner_type == 'domain_name_privacy') {
          this.$store.dispatch('partnerRequest/repairPrivacy', { pr_uuid: this.partnerRequest.partner_request_uuid, domainName: this.domainName })
            .then(() => {
              this.$store.dispatch('partnerRequest/fetchPartnerRequests', { uuid: this.partnerRequest.partner_request_uuid })
                .then(() => {
                  this.$router.push({
                    name: 'OrderTasksView',
                    params: { uuid: this.order_uuid }
                  });
                });
            });
        }
      }
    }
  },
  data() {
    return {
      domainName: 'example.com',
      valid_domain_name: true,
      invalidDomainNameAlertMessage: {
        primary: 'This domain name is missing a TLD suffix',
        secondary: 'Include one of [.com, .org, .biz, .net or .us] allowed TLDs. Refresh the page and try again',
        type: 'alert alert-danger'
      }
    };
  }
};
</script>

<style lang="scss" scoped>

</style>
